export function User({
  size = 24,
  color = 'var(--color-deepBlue)',
  secondary = 'var(--color-focusBlue)',
}: {
  size?: number
  color?: string
  secondary?: string
}): JSX.Element {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 22V16H20V22" stroke={color} strokeWidth="2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9004 4.52002C10.2435 4.52002 8.90039 5.86317 8.90039 7.52002C8.90039 9.17687 10.2435 10.52 11.9004 10.52C13.5572 10.52 14.9004 9.17687 14.9004 7.52002C14.9004 5.86317 13.5572 4.52002 11.9004 4.52002ZM6.90039 7.52002C6.90039 4.7586 9.13897 2.52002 11.9004 2.52002C14.6618 2.52002 16.9004 4.7586 16.9004 7.52002C16.9004 10.2814 14.6618 12.52 11.9004 12.52C9.13897 12.52 6.90039 10.2814 6.90039 7.52002Z"
        fill={color}
      />
      <mask id="mask0" maskUnits="userSpaceOnUse" x="6" y="2" width="11" height="11">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9004 4.52002C10.2435 4.52002 8.90039 5.86317 8.90039 7.52002C8.90039 9.17687 10.2435 10.52 11.9004 10.52C13.5572 10.52 14.9004 9.17687 14.9004 7.52002C14.9004 5.86317 13.5572 4.52002 11.9004 4.52002ZM6.90039 7.52002C6.90039 4.7586 9.13897 2.52002 11.9004 2.52002C14.6618 2.52002 16.9004 4.7586 16.9004 7.52002C16.9004 10.2814 14.6618 12.52 11.9004 12.52C9.13897 12.52 6.90039 10.2814 6.90039 7.52002Z"
          fill={color}
        />
      </mask>
      <g mask="url(#mask0)">
        <rect x="12" y="7" width="8" height="6" fill={secondary} />
      </g>
    </svg>
  )
}
