import { mixins } from '../../../styles'
import css from 'styled-jsx/css'

export const styles = css`
  .CarouselFullScreen {
    position: absolute;
    background-color: var(--color-deepBlue);
    height: 100vh;
    width: 100vw;
    z-index: 65;
    top: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  :global(#body-header),
  :global(.PageTemplate__Main) {
    display: none !important;
  }

  .CarouselFullScreen :global(.Carousel) {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 16px 0;
    justify-content: center;
    margin: auto;
  }

  .CarouselFullScreen__Close {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
  }

  .CarouselFullScreen__Close button {
    background-color: var(--color-white);
    border-radius: 50%;
    border: hidden;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .CarouselFullScreen :global(.swiper-wrapper > *) {
    position: relative;
    overflow: hidden;
    height: 100%;
  }

  .CarouselFullScreen :global(.swiper-wrapper * img) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .CarouselFullScreen :global(.swiper-wrapper > img) {
    width: 100%;
    height: auto;
  }

  @media ${mixins.mediaQuery.tablet} {
    .CarouselFullScreen :global(.swiper-wrapper) {
      grid-auto-columns: 100%;
      grid-gap: 10px;
    }

    .CarouselFullScreen :global(.swiper-wrapper > img) {
      width: 100%;
      height: 100%;
    }
  }

  @media ${mixins.mediaQuery.tabletPaysage} {
    .CarouselFullScreen :global(.swiper-wrapper > *) {
      position: relative;
      overflow: hidden;
      height: initial;
      width: initial;
    }

    .CarouselFullScreen :global(.Carousel) {
      height: 100%;
    }
  }
`
