import { ReactNode, Context, useContext, useEffect } from 'react'
import { createContext, useState } from 'react'
import { getDeviceOS, isBot, isChrome } from '../helpers/client'
import { setCookie, getCookie } from '../helpers/cookies'
import { ApiResponseContext } from './ApiResponseContext'
import { useAmp } from 'next/amp'
import { BANNER_COOKIE_KEY } from '../constants/cookies'

export const DownloadBannerContext: Context<{
  isDisplayed: boolean
  handleClose: (time: string) => void
}> = createContext({
  isDisplayed: false,
  handleClose: () => null,
})

const isBrowserTargeted = (userAgent?: string) => {
  const deviceOS = getDeviceOS(userAgent)
  const isChromeBrowser = isChrome(userAgent)
  const isGoogleBot = isBot(userAgent)
  return !isGoogleBot && (deviceOS === 'android' || (deviceOS === 'ios' && isChromeBrowser))
}

export function DownloadBannerContextWrapper({
  enabled,
  children,
}: {
  enabled?: boolean
  children?: ReactNode
}): JSX.Element {
  const isAmp = useAmp()
  const { ssr } = useContext(ApiResponseContext)
  const [isDisplayed, setIsDisplayed] = useState(false)

  useEffect(() => {
    if (!enabled) return
    const isCorrectDevice = isBrowserTargeted()
    const isClosedManually = getCookie(BANNER_COOKIE_KEY)

    setIsDisplayed(!isAmp && isCorrectDevice && !isClosedManually)
  }, [ssr])

  const handleClose = (time) => {
    setCookie({ name: BANNER_COOKIE_KEY, value: 'true', days: time ? Number(time) / 24 : 1 })
    setIsDisplayed(false)
  }

  return (
    <DownloadBannerContext.Provider value={{ isDisplayed: isDisplayed, handleClose: handleClose }}>
      {children}
    </DownloadBannerContext.Provider>
  )
}
