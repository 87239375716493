import type { ReactNode, ReactElement } from 'react'
import type { IViewport } from '../../../hook/useViewport'
import { useViewport } from '../../../hook/useViewport'

export interface IViewportCondition {
  children: ReactNode | ReactElement
  visibleViewports: IViewport[]
  placeHolder?: ReactNode | ReactElement
}

export function ViewportCondition({
  children,
  visibleViewports,
  placeHolder,
}: IViewportCondition): JSX.Element {
  const { viewport } = useViewport()
  const isVisible = visibleViewports.includes(viewport)
  return isVisible ? <>{children}</> : <>{placeHolder}</>
}
