import type { IPicture } from '../../../types/pictures'
import { useAmp } from 'next/amp'
import { Carousel } from '../Carousel'
import { SVGIcon } from '../../atoms/SVGIcon'
import { theme } from '../../../styles'
import { styles } from './styles'

export interface ICarouselFullScreen {
  setHasFullScreen: (hasFullScreen: boolean) => void
  elementList: IPicture[]
}

export function CarouselFullScreen({ setHasFullScreen, elementList }: ICarouselFullScreen) {
  const isAmp = useAmp()
  const handleOnClick = () => setHasFullScreen(false)

  if (!elementList.length) return null

  return (
    <>
      <div className="CarouselFullScreen">
        <div className="CarouselFullScreen__Close">
          <button onClick={handleOnClick}>
            <SVGIcon name="close" size={32} primaryColor={theme.cssVars.deepBlue} />
          </button>
        </div>
        <Carousel pagination="all">
          {elementList.map((picture, index) => (
            <img
              key={index}
              alt={picture?.caption || ''}
              loading={picture?.lazyload ? 'lazy' : 'eager'}
              importance="high"
              fetchpriority={isAmp ? undefined : 'high'}
              src={picture?.fullsizeImage}
            />
          ))}
        </Carousel>
      </div>
      <style jsx>{styles}</style>
    </>
  )
}
