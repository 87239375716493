import { useTMSHitForMenuBurger } from '../../../hook/useTMSClickHit'
import { Link } from '../Link'
import { SVGIcon } from '../SVGIcon'

export interface IMenuDirect {
  title: string
  elementList: Array<{ title: string; link: string }>
}

export function MenuDirect({ title, elementList }: IMenuDirect): JSX.Element {
  const { menuHit } = useTMSHitForMenuBurger()
  return (
    <>
      <div className="MenuDirect width-100">
        <div className="MenuDirect__Title">
          <SVGIcon name="direct" size={13} /> {title}
        </div>
        <ul className="MenuDirect__List">
          {elementList?.length &&
            elementList.map(({ title: linkTitle, link }) => (
              <li key={link} className="MenuDirect__Item" onClick={() => menuHit(title, linkTitle)}>
                <Link url={link}>{linkTitle}</Link>
              </li>
            ))}
        </ul>
      </div>

      <style jsx>{`
        .MenuDirect {
          padding: 28px 30px 28px;
        }

        .MenuDirect__Title {
          font-size: 30px;
          font-weight: 700;
          line-height: 116%;
          color: var(--color-deepBlue);
          margin: 0;
          margin-bottom: 38px;
        }

        .MenuDirect__Title :global(svg) {
          margin-bottom: 4px;
        }

        .MenuDirect__List {
          list-style: none;
          margin: 0;
          padding: 0 0 0 30px;
        }

        .MenuDirect__Item {
          color: var(--color-deepBlue);
          margin-bottom: 38px;
        }

        .MenuDirect__Item:last-child {
          color: var(--color-deepBlue);
          margin-bottom: 0;
        }
      `}</style>
    </>
  )
}
