import { useContext, useEffect } from 'react'

import { TagCommanderContext } from '../../../contexts/tagcommander'
import { DownloadBannerContext } from '../../../contexts/DownloadBanner'

import { theme } from '../../../styles'
import { SVGIcon } from '../../atoms/SVGIcon'

export interface IDownloadBanner {
  wordings?: {
    title?: string
    description?: string
    button?: string
  }
  cookie?: {
    time?: string
  }
}

export function DownloadBanner({ wordings, cookie }: IDownloadBanner): JSX.Element {
  const { hit, isTagCommanderReady } = useContext(TagCommanderContext)
  const { isDisplayed, handleClose } = useContext(DownloadBannerContext)

  const handleLinkClick = () => {
    hit(
      {
        screen_clickableElementName: 'bandeau-web-ouvrir-app',
      },
      { isClickEvent: true },
    )
    closeBanner()
  }

  const closeBanner = () => {
    handleClose(cookie?.time)
  }

  useEffect(() => {
    // If download banner is displayed, send a click hit
    // https://e-tf1.atlassian.net/browse/LCI-8066
    if (isDisplayed && isTagCommanderReady) {
      hit({ screen_clickableElementName: 'affichage-bandeau-ouvrir-app' }, { isClickEvent: true })
    }
  }, [isTagCommanderReady])

  if (!isDisplayed) {
    return null
  }

  return (
    <>
      <div className="DownloadBanner flex justify-between items-center">
        <SVGIcon className="flex justify-center items-center" onClick={closeBanner} name="close" />
        <div className="flex items-center">
          <img
            width="53"
            height="53"
            src="/images/tf1info-logo-app-152x152.png"
            className="DownloadBanner__App_icon"
            alt="croix"
          />
          <div className="DownloadBanner__Text flex flex-column justify-start">
            <div className="DownloadBanner__Text__Title">{wordings?.title}</div>
            <div className="DownloadBanner__Text__description">{wordings?.description}</div>
          </div>
        </div>
        <a
          className="DownloadBanner__Download"
          href="https://tf1infos.page.link/app"
          onClick={handleLinkClick}
        >
          {wordings?.button || 'Ouvrir'}
        </a>
      </div>

      <style jsx>{`
        .DownloadBanner {
          background-color: ${theme.colors.lightBlue};
          width: 100%;
          height: 83px;
          padding: 16px 15px 16px 10px;
        }

        .DownloadBanner :global(> svg) {
          margin-right: 13px;
        }

        .DownloadBanner__App_icon {
          height: 53px;
          border-radius: 10px;
        }

        .DownloadBanner__Text {
          color: ${theme.colors.deepBlue};
          font-weight: 700;
          font-size: 12px;
          margin: 0 10px;
          line-height: 14px;
        }

        .DownloadBanner__Text__Title {
          font-weight: 900;
          text-transform: uppercase;
        }

        .DownloadBanner__Download {
          background-color: ${theme.colors.focusBlue};
          border: none;
          padding: 6px 8px;
          border-radius: 4px;

          color: white;
          line-height: 20px;
          font-size: 13px;
          font-weight: 700;
        }
      `}</style>
    </>
  )
}
