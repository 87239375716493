import { theme } from '../styles'

export function getContrastColor(color: string) {
  const limit = 160

  if (!color.match(/^#(?:[\da-f]{3,4}){1,2}$/i)) return undefined

  if (color.length === 4) {
    const [a, b, c] = color.slice(1).split('')
    color = `#${a}${a}${b}${b}${c}${c}`
  }

  const red = parseInt(color.substring(1, 3), 16)
  const green = parseInt(color.substring(3, 5), 16)
  const blue = parseInt(color.substring(5, 7), 16)

  return red * 0.299 + green * 0.587 + blue * 0.114 > limit
    ? theme.cssVars.deepBlue
    : theme.cssVars.white
}
