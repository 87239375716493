import { mixins } from '../../../styles'

export type IInput = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  value?: string
  children?: React.ReactNode
} & React.InputHTMLAttributes<HTMLInputElement>

export function Input({
  children,
  onChange,
  onKeyDown = () => null,
  value,
  ...rest
}: IInput): JSX.Element {
  return (
    <>
      <div className="Input flex">
        <input
          className="Input__Input width-100 height-100"
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={value}
          {...rest}
        />
        {children && <div className="Input__Children flex">{children}</div>}
      </div>

      <style jsx>{`
        .Input {
          position: relative;
          height: 66px;
          background-color: var(--color-lightBlue);
          border-radius: 5px;
        }

        .Input__Children {
          padding-right: 9px;
        }

        @media ${mixins.mediaQuery.desktop} {
          .Input {
            justify-content: space-between;
          }
        }

        .Input img {
          width: 34px;
          margin-left: 16px;
        }

        .Input__Input {
          border-color: transparent;
          background-color: var(--color-lightBlue);
          margin-left: 16px;
        }

        input::placeholder {
          font-family: var(--font-overpass);
          color: var(--color-deepBlue);
          font-size: 13px;
          font-weight: 700;
          margin-left: 8px;
          font-weight: normal;
          opacity: 0.5;
        }

        input {
          color: var(--color-deepBlue);
          font-size: 15px;
          font-weight: 700;
        }

        input:focus-visible {
          outline: transparent;
        }

        @media ${mixins.mediaQuery.desktop} {
          .Input__Icon {
            margin: 13px;
          }
        }
      `}</style>
    </>
  )
}
