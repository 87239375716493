import type { ReactNode } from 'react'

import { theme } from '../../../styles'
import { Link } from '../Link'
import { Icon } from '../Icon'
import { SVGIcon } from '../SVGIcon'
import { getContrastColor } from '../../../helpers/colors'

export interface ITopic {
  className?: string
  color?: string
  textColor?: string
  link?: string
  title?: ReactNode
  categoryName?: string
  onClick?: () => void
  icon?: string
}

export function Topic({
  className,
  color = theme.cssVars.purple,
  link,
  title,
  categoryName,
  onClick,
  icon,
}: ITopic): JSX.Element {
  const RenderComponent = link ? Link : 'div'
  const textColor = getContrastColor(color)
  return (
    <>
      <RenderComponent url={link || undefined} onClick={link ? onClick : undefined}>
        <div
          className={[
            'Topic flex items-center',
            link ? 'justify-between' : 'justify-start',
            className ? `${className}` : '',
          ]
            .filter(Boolean)
            .join(' ')}
        >
          {icon ? (
            <div className="Topic__Icon">
              <Icon name={icon} primaryColor={textColor} secondaryColor={textColor} size="25px" />
            </div>
          ) : null}
          <div className="Topic__Container flex flex-column">
            {!!categoryName && <span className="Topic__CategoryName">{categoryName}</span>}
            <span className="Topic__Container__Title">{title}</span>
          </div>
          {!!link && (
            <div className="Topic__Arrow flex items-center">
              <SVGIcon name="arrow" primaryColor={textColor} />
            </div>
          )}
        </div>
      </RenderComponent>

      <style jsx>{`
        .Topic {
          background-color: ${color};
        }

        .Topic__Container {
          ${link ? 'cursor: pointer;' : ''}
        }

        .Topic__Container__Title,
        .Topic__CategoryName {
          color: ${textColor};
        }
      `}</style>
      <style jsx>{`
        .Topic {
          min-height: 80px;
          width: 100%;
          padding: 15px ${theme.block.marginLR}px;
          border-radius: 8px;
        }

        .Topic__Icon {
          margin-right: 15px;
        }

        .Topic__CategoryName {
          font-weight: 400;
          font-size: 13px;
          margin-bottom: 6px;
          display: block;
        }

        .Topic__Container {
          height: 100%;
          flex: 12;
        }

        .Topic__Container__Title {
          font-weight: 800;
          margin-top: 2px;
        }

        .Topic__Arrow {
          margin-left: 10px;
        }
      `}</style>
    </>
  )
}
