import { memo } from 'react'
import { theme } from '../../../styles'

export type IHeadline = {
  htmlText: string
  id?: string
}

export const Headline = memo(function Headline({ htmlText, id }: IHeadline): JSX.Element {
  return (
    <>
      <h2
        className="Headline flex flex-column justify-center"
        id={id}
        dangerouslySetInnerHTML={{ __html: htmlText }}
      />
      <style jsx>{`
        .Headline {
          color: ${theme.cssVars.fontColor};
          font-size: 38px;
          text-align: center;
          font-weight: 100;
          margin: 0;
        }

        .Headline :global(strong) {
          font-weight: 800;
        }
      `}</style>
    </>
  )
})
