import type { MouseEventHandler } from 'react'
import { theme } from '../../../styles'

export interface IButtonBanner {
  backgroundColor?: string
  children: React.ReactNode | string
  disabled?: boolean
  href?: string
  onClick?: MouseEventHandler<HTMLAnchorElement> & MouseEventHandler<HTMLButtonElement>
  textColor?: string
  type?: 'button' | 'submit' | 'reset'
  testId?: string
  id?: string
}

export function ButtonBanner({
  backgroundColor,
  children,
  disabled,
  href,
  onClick,
  textColor,
  type,
  testId,
  id,
}: IButtonBanner): JSX.Element {
  const RenderComponent = href ? 'a' : 'button'

  return (
    <>
      <RenderComponent
        className="ButtonBanner flex justify-start items-center width-100"
        href={href ? href : undefined}
        type={href ? undefined : type || 'button'}
        onClick={onClick}
        disabled={disabled}
        data-testid={testId}
        id={id}
      >
        {children}
      </RenderComponent>

      <style jsx>{`
        .ButtonBanner {
          background: ${backgroundColor || theme.cssVars.deepBlue};
        }
        .ButtonBanner {
          color: ${textColor || theme.cssVars.white};
        }
      `}</style>
      <style jsx>{`
        .ButtonBanner {
          cursor: pointer;
          outline: none;
          border: none;
          text-decoration: none;

          height: 90px;

          padding: 20px 24px;

          font-style: normal;
          font-size: 20px;
          font-weight: 800;
          line-height: 133%;
        }

        .ButtonBanner:disabled,
        .ButtonBanner[disabled] {
          cursor: not-allowed;
          background: var(--color-gray);
        }
      `}</style>
    </>
  )
}
