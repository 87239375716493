import css from 'styled-jsx/css'
import { theme } from '../../../../styles'

export const styles = css.global`
  .PaginationProgress {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
  }

  .PaginationProgress__item {
    display: flex;
    flex: 1;
    height: 0.22em;
    max-width: 120px;
    background-color: ${theme.cssVars.grayOpacity};
    border-radius: 15px;
    cursor: pointer;
  }

  .PaginationProgress__progress-bar {
    background-color: ${theme.cssVars.white};
    opacity: 0;
    width: 100%;
    border-radius: 15px;
  }

  .PaginationProgress__progress-bar--active {
    opacity: 1;
    animation-fill-mode: both;
  }

  .PaginationProgress--white-bg .PaginationProgress__item {
    background-color: ${theme.cssVars.lightGrayBlue};
  }

  .PaginationProgress--white-bg .PaginationProgress__progress-bar {
    background-color: ${theme.cssVars.deepBlue};
  }

  @keyframes progressBar {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
`
