import { useEffect, useRef } from 'react'
import { useAmp } from 'next/amp'
import { isServer } from '../helpers/client'

interface IUseModalAccessibility {
  show: boolean
  ref: HTMLElement
  onEscape: () => void
}

export function useRefocusOnModalClose(isOpen: boolean, elementToFocus: HTMLElement) {
  const hasOpenedOnce = useRef(false)

  useEffect(
    function handleFocusOnMenuClose() {
      if (!isOpen && hasOpenedOnce.current) {
        elementToFocus?.focus()
      }
      if (isOpen) {
        hasOpenedOnce.current = true
      }
    },
    [isOpen],
  )
}

export function useModalAccessibility({ show, ref, onEscape }: IUseModalAccessibility): {
  inert?: string
} {
  const isAmp = useAmp()
  function removeInertAttribute() {
    const allInertElements = document?.querySelectorAll('[inert]:not([role="dialog"])')
    // remove inert attribute from all elements not having role dialog
    allInertElements?.forEach((element) => {
      element.removeAttribute('inert')
    })
  }

  // Handle keydown event for escape
  function handleKeydown(e: KeyboardEvent) {
    const isEscapePressed = e.key === 'Escape' || e.keyCode === 27
    if (isEscapePressed) {
      onEscape()
      return
    }
  }

  useEffect(
    function toggleBodyElementFocus() {
      if (isServer()) {
        return
      }
      if (show) {
        const firstFocusableElement: HTMLElement =
          // Add data-first-focus attribute to the first focusable element of the modal
          ref?.querySelector('[data-first-focus]') ||
          ref?.querySelector(
            ':not([tabindex="-1"]):where(button, [role="button"], [href], input, select, textarea, [tabindex])',
          )
        if (firstFocusableElement) {
          firstFocusableElement?.focus?.({ preventScroll: false })
        }
        // add inert attribute to all other root elements (the modal itself should be present at root level)
        const allRootElements = document.querySelector('#__next').childNodes
        allRootElements?.forEach((node) => {
          const nodeElement = node as HTMLElement
          if (nodeElement === ref) return
          nodeElement.setAttribute('inert', 'true')
        })

        document.addEventListener('keydown', handleKeydown)
      }
      return () => {
        removeInertAttribute()
        document.removeEventListener('keydown', handleKeydown)
      }
    },
    [show],
  )

  return { inert: !show && !isAmp ? 'true' : undefined }
}
