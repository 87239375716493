import type { IBlock } from '../Block'

import { memo } from 'react'
import { useRouter } from 'next/router'
import { Block } from '../Block'
import { Home } from '../Home'
import { HOME_URLS } from '../../../constants/home'

export interface IBlockList {
  name?: string
  data: IBlock[]
  Container?: 'article'
}

const staticMenuBlocks = [
  'menu-header',
  'menu-asuivre',
  'menu-directs',
  'menu-categories',
  'menu-footer',
]

export const BlockList = memo(function BlockList({
  name,
  data,
  Container,
}: IBlockList): JSX.Element {
  const { asPath } = useRouter()
  const isHomeBlock =
    ['body-header', 'body', 'left-sidebar', 'right-sidebar'].includes(name) &&
    !!HOME_URLS.find((v) => asPath === v || v === `/${asPath.split('/')[1]}/`)
  const isMenu = name === 'menu'

  if (!data?.length) {
    return null
  }

  const dataByName = data.find((val) => val.key === name)
  const subData = dataByName?.data as IBlock[]
  let indexSubData = subData?.length - 1 || 0

  if (Container === 'article') {
    const hasWallPoool = subData.findIndex((val) => val.key === 'poool-access') !== -1
    const IndexArticleAuthorBlock = subData.findIndex(({ key }) => key === 'article-author')

    if (IndexArticleAuthorBlock) {
      // If have poool wall, take the author block from the article block
      indexSubData = hasWallPoool ? IndexArticleAuthorBlock : IndexArticleAuthorBlock + 1
    }
  }

  if (!subData?.length) {
    return null
  }

  if (isMenu) {
    return (
      <>
        {subData.map(({ key, data: blockData }, index) => (
          <Block
            key={`${key}-${index}`}
            name={!staticMenuBlocks.includes(key) ? 'menu-links' : key}
            data={blockData}
          />
        ))}
      </>
    )
  }

  if (!isHomeBlock) {
    const SubDataRender = (data) =>
      data.map(({ key, data: blockData }, index) => (
        <Block key={`${key}-${index}`} name={key} data={blockData} />
      ))

    if (Container) {
      const subDataIncludeContainer = subData.slice(0, indexSubData)
      const subDataExcludeContainer = subData.slice(indexSubData)

      return (
        <>
          {subDataExcludeContainer ? (
            <>
              <Container>{SubDataRender(subDataIncludeContainer)}</Container>
              {SubDataRender(subDataExcludeContainer)}
            </>
          ) : (
            <Container>{SubDataRender(subData)}</Container>
          )}
        </>
      )
    }

    return <>{SubDataRender(subData)}</>
  }

  return <Home data={subData} />
})
