import { INTERVAL_TIME } from '../Carousel'
import { styles } from './styles'

export interface PaginationProgressProps {
  numberOfItems: number
  currentIndex?: number
  withWhiteBackground?: boolean
  className?: string
  onClick?: (index: number) => void
}

export function PaginationProgress({
  numberOfItems,
  currentIndex = 0,
  withWhiteBackground,
  className,
  onClick = () => null,
}: PaginationProgressProps) {
  return (
    <>
      <nav>
        <ul
          className={[
            'PaginationProgress',
            withWhiteBackground && 'PaginationProgress--white-bg',
            className,
          ]
            .join(' ')
            .trim()}
        >
          {Array.from({ length: numberOfItems }).map((_, index) => (
            <li key={index} className="PaginationProgress__item" onClick={() => onClick(index)}>
              <div
                data-progress-index={index}
                className={[
                  'PaginationProgress__progress-bar',
                  index === currentIndex ? 'PaginationProgress__progress-bar--active' : `${index}`,
                ]
                  .join(' ')
                  .trim()}
              />
            </li>
          ))}
        </ul>
      </nav>

      <style jsx global>
        {styles}
      </style>
      <style jsx>{`
        .PaginationProgress__progress-bar--active {
          animation: progressBar ${Math.floor(INTERVAL_TIME / 1000)}s ease-in-out;
        }
      `}</style>
    </>
  )
}
