import type { ComponentType } from 'react'
import type { BlockTypes } from '@etf1-interne/tf1info_types_news'

import type { IArticleImage } from '../../atoms/ArticleImage'
import type { IExamSearchResults } from '../../atoms/ExamSearchResults'
import type { IExamResult } from '../../atoms/ExamResult'
import type { IPooolAccess } from '../../atoms/PooolAccess'
import type { IPooolEngage } from '../../atoms/PooolEngage'

import type { IExamenSearch } from '../../molecules/ExamSearch'
import type { INewsFeed } from '../../molecules/NewsFeed'
import type { IPersonalityHeader } from '../../molecules/PersonalityHeader'
import type { IHeaderTitleBlock } from '../../molecules/HeaderTitleBlock'
import type { ITagList } from '../../molecules/TagList'

import type { ICarouselNewsletter } from '../../organisms/CarouselNewsletter'
import type { IPersonalityDetails } from '../../organisms/PersonalityDetails'
import type { IAuthorList } from '../../organisms/AuthorList'
import type { IPromo } from '../../organisms/Promo'
import type { ICarouselImages } from '../../organisms/CarouselImages'

import type { IBounceNotification } from '../../molecules/BounceNotification'

import dynamic from 'next/dynamic'

//LAZY import in chunk Block
import { Container } from '../../atoms/Container'
import { AllNews } from '../../organisms/AllNews'
import { FooterNav } from '../../atoms/FooterNav'
import { Header } from '../../organisms/Header'
import { Menu } from '../../atoms/Menu'
import { MenuHeader } from '../../organisms/MenuHeader'
import { MenuTopics } from '../../molecules/MenuTopics'
import { MenuLinksOpen } from '../../molecules/MenuLinksOpen'
import { MenuDirect } from '../../atoms/MenuDirect'
import { MenuFooter } from '../../molecules/MenuFooter'
import { MenuLinks } from '../../atoms/MenuLinks'

//DYNAMIC import in chunk Standard
const Standard = (module) =>
  dynamic(() =>
    import('./Standard').then((modules) => modules?.[module] || null).catch((e) => null),
  )

//DYNAMIC import in chunk Article
const Article = (module) =>
  dynamic(() => import('./Article').then((modules) => modules?.[module] || null).catch((e) => null))

//DYNAMIC import in chunk Election
const Election = (module) =>
  dynamic(() =>
    import('./Election').then((modules) => modules?.[module] || null).catch((e) => null),
  )

//DYNAMIC import in chunk List
const List = (module) =>
  dynamic(() => import('./List').then((modules) => modules?.[module] || null).catch((e) => null))

// prettier-ignore
const mappings: Record<
  IBlock['key'],
  {
    Component: ComponentType
    isFullWidthTabletLandscape?: boolean
    isFullWidthDesktop?: boolean
    isFullRight?: boolean
    noContainer?: boolean
  }
> = {
  'action-button': {
    Component: dynamic(() =>
      import('../../atoms/ActionButton').then((module) => module.ActionButton),
    ),
    noContainer: true,
  },
  'all-news': {
    Component: AllNews,
    noContainer: true,
  },
  'article-author': {
    Component: Article('AuthorArticle'),
    noContainer: true,
  },
  'article-bounce-folder': {
    Component: Article('ArticleBounceFolder'),
  },
  'article-breadcrumb': {
    Component: Article('Breadcrumb'),
    noContainer: true,
  },
  'article-chapo': {
    Component: Article('ArticleChapo'),
    noContainer: true,
  },
  'article-embed': {
    Component: Article('Embed'),
  },
  'article-extract': {
    Component: Article('InterTitle'),
    noContainer: true,
  },
  'article-header-image': {
    Component: Article('ArticleHeaderImage'),
  },
  'article-header-live': {
    Component: Article('ArticleHeaderVideo'),
  },
  'article-header-video': {
    Component: Article('ArticleHeaderVideo'),
  },
  'article-image': {
    Component: Article('ArticleImage'),
    noContainer: true,
  },
  'article-list-gen': {
    Component: Article('ArticleList'),
  },
  'article-list': {
    Component: List('ArticleList'),
  },
  'article-paragraph': {
    Component: Article('Paragraph'),
    noContainer: true,
  },
  'article-quote': {
    Component: Article('Quote'),
    noContainer: true,
  },
  'article-read-more': {
    Component: Article('ReadMoreArticle'),
    noContainer: true,
  },
  'article-social-share': {
    Component: Article('SocialNetworkSharing'),
  },
  'article-topic': {
    Component: Article('ArticleTopic'),
  },
  'article-video': {
    Component: Article('ArticleVideo'),
    noContainer: true,
  },
  'author-header': {
    Component: dynamic(() =>
      import('../../molecules/AuthorHeader').then((module) => module.AuthorHeader),
    ),
  },
  'author-list': {
    Component: dynamic(() =>
      import('../../organisms/AuthorList').then((module) => module.AuthorList),
    ),
  },
  promo: {
    Component: dynamic(() => import('../../organisms/Promo').then((module) => module.Promo)),
  },
  breadcrumb: {
    Component: Standard('Breadcrumb'),
  },
  'category-header': {
    Component: dynamic(() =>
      import('../../organisms/TopicHeader').then((module) => module.TopicHeader),
    ),
  },
  'carousel-highlights': {
    Component: dynamic(() =>
      import('../../organisms/CarouselHighlights').then((module) => module.CarouselHighlights),
    ),
    isFullWidthTabletLandscape: true,
    isFullWidthDesktop: true,
  },
  'carousel-images': {
    Component: dynamic(() =>
      import('../../organisms/CarouselImages').then((module) => module.CarouselImages),
    ),
    isFullWidthTabletLandscape: true,
    isFullWidthDesktop: true,
  },
  'carousel-newsletters': {
    Component: dynamic(() =>
      import('../../organisms/CarouselNewsletter').then((module) => module.CarouselNewsletter),
    ),
  },
  'cookies-banner': {
    Component: dynamic(() =>
      import('../../molecules/CookiesBanner').then((module) => module.CookiesBanner),
    ),
    noContainer: true,
  },
  'bounce-notification': {
    Component: Standard('BounceNotification'),
    noContainer: true,
  },
  'election-department-list': {
    Component: Election('ElectionDepartmentList'),
  },
  'election-header': {
    Component: Election('ElectionHeader'),
  },
  'election-manual-results': {
    Component: dynamic(() =>
      import('../../molecules/ElectionResults').then((module) => module.ElectionResults),
    ),
  },
  'election-search': {
    Component: Election('ElectionSearch'),
  },
  'election-region-list': {
    Component: Election('ElectionRegionList'),
  },
  'election-results': {
    Component: Election('ElectionResultsAuto'),
  },
  'election-town-list': {
    Component: Election('ElectionTownList'),
  },
  'election-district-list': {
    Component: Election('ElectionDistrictList'),
  },
  'event-category-header': {
    Component: dynamic(() =>
      import('../../molecules/EventCategoryHeader').then((module) => module.EventCategoryHeader),
    ),
  },
  'event-header': {
    Component: dynamic(() =>
      import('../../molecules/EventHeader').then((module) => module.EventHeader),
    ),
  },
  'event-vote': {
    Component: dynamic(() =>
      import('../../molecules/EventVote').then((module) => module.EventVote),
    ),
  },
  'exam-header': {
    Component: dynamic(() =>
      import('../../molecules/HeaderTitleBlock').then((module) => module.HeaderTitleBlock),
    ),
  },
  'exam-search': {
    Component: dynamic(() =>
      import('../../molecules/ExamSearch').then((module) => module.ExamSearch),
    ),
  },
  'exam-search-results': {
    Component: dynamic(() =>
      import('../../atoms/ExamSearchResults').then((module) => module.ExamSearchResults),
    ),
  },
  'exam-result': {
    Component: dynamic(() => import('../../atoms/ExamResult').then((module) => module.ExamResult)),
  },
  'footer-nav': {
    Component: FooterNav,
    noContainer: true,
  },
  'gigya-screen': {
    Component: dynamic(() =>
      import('../../atoms/GigyaScreen').then((module) => module.GigyaScreen),
    ),
  },
  'header-navigation': {
    Component: Header,
    noContainer: true,
  },
  'header-page': {
    Component: dynamic(() =>
      import('../../molecules/HeaderTitleBlock').then((module) => module.HeaderTitleBlock),
    ),
    isFullWidthTabletLandscape: true,
    isFullWidthDesktop: true,
  },
  headlines: {
    Component: dynamic(() =>
      import('../../organisms/Headlines').then((module) => module.Headlines),
    ),
  },
  hello: {
    Component: dynamic(() => import('../../atoms/Hello').then((module) => module.Hello)),
    noContainer: true,
  },
  'home-user': {
    Component: dynamic(() =>
      import('../../templates/HomeForYou').then((module) => module.HomeForYou),
    ),
    noContainer: true,
  },
  'keyword-header': {
    Component: dynamic(() =>
      import('../../molecules/KeywordHeader').then((module) => module.KeywordHeader),
    ),
  },
  'link-list': {
    Component: dynamic(() => import('../../organisms/LinkList').then((module) => module.LinkList)),
  },
  lives: {
    Component: dynamic(() =>
      import('../../organisms/LivesAndChannels').then((module) => module.LivesAndChannels),
    ),
    noContainer: true,
  },
  'live-list': {
    Component: dynamic(() => import('../../molecules/LiveList').then((module) => module.LiveList)),
  },
  'market-event-home': {
    Component: dynamic(() =>
      import('../../organisms/MarketEventHome').then((module) => module.MarketEventHome),
    ),
  },
  menu: {
    Component: Menu,
  },
  'menu-header': {
    Component: MenuHeader,
  },
  'menu-asuivre': {
    Component: MenuTopics,
  },
  'menu-categories': {
    Component: MenuLinksOpen,
  },
  'menu-directs': {
    Component: MenuDirect,
  },
  'menu-footer': {
    Component: MenuFooter,
  },
  'menu-links': {
    Component: MenuLinks,
  },
  'most-read-articles': {
    Component: AllNews,
  },
  'multi-live': {
    Component: dynamic(() =>
      import('../../organisms/MultiLive').then((module) => module.MultiLive),
    ),
  },
  newsfeed: {
    Component: dynamic(() => import('../../molecules/NewsFeed').then((module) => module.NewsFeed)),
  },
  outbrain: {
    Component: dynamic(() => import('../../atoms/Outbrain').then((module) => module.Outbrain)),
  },
  'outbrain-drawer': {
    Component: dynamic(() =>
      import('../../atoms/Outbrain/OutbrainDrawer').then((module) => module.OutbrainDrawer),
    ),
  },
  'personality-header': {
    Component: dynamic(() =>
      import('../../molecules/PersonalityHeader').then((module) => module.PersonalityHeader),
    ),
  },
  'personality-details': {
    Component: dynamic(() =>
      import('../../organisms/PersonalityDetails').then((module) => module.PersonalityDetails),
    ),
  },
  'player-live': {
    Component: dynamic(() =>
      import('../../molecules/PlayerLive').then((module) => module.PlayerLive),
    ),
  },
  'poool-access': {
    Component: dynamic(() =>
      import('../../atoms/PooolAccess').then((module) => module.PooolAccess),
    ),
  },
  'poool-engage': {
    Component: dynamic(() =>
      import('../../atoms/PooolEngage').then((module) => module.PooolEngage),
    ),
  },
  pub: {
    Component: dynamic(() =>
      import('../../molecules/DisplayCoffee').then((module) => module.DisplayCoffee),
    ),
  },
  'topic-chronology': {
    Component: dynamic(() =>
      import('../../organisms/ArticleList').then((module) => module.ArticleList),
    ),
  },
  'topic-header': {
    Component: dynamic(() =>
      import('../../organisms/TopicHeader').then((module) => module.TopicHeader),
    ),
  },
  'program-header': {
    Component: dynamic(() =>
      import('../../molecules/ProgramHeader').then((module) => module.ProgramHeader),
    ),
  },
  'program-list': {
    Component: dynamic(() =>
      import('../../molecules/ProgramList').then((module) => module.ProgramList),
    ),
    isFullWidthDesktop: true,
    isFullWidthTabletLandscape: true,
  },
  'related-tags': {
    Component: dynamic(() =>
      import('../../molecules/StackTags').then((module) => module.StackTags),
    ),
  },
  'rich-video': {
    Component: dynamic(() =>
      import('../../organisms/RichVideo').then((module) => module.RichVideo),
    ),
    noContainer: true,
  },
  'search-results': {
    Component: dynamic(() =>
      import('../../organisms/SearchResults').then((module) => module.SearchResults),
    ),
  },
  'topic-list': {
    Component: dynamic(() =>
      import('../../molecules/TopicList').then((module) => module.TopicList),
    ),
    isFullWidthDesktop: true,
  },
  pagination: {
    Component: dynamic(() =>
      import('../../molecules/Pagination').then((module) => module.Pagination),
    ),
  },
  'program-video-list': {
    Component: dynamic(() =>
      import('../../organisms/ReplayList').then((module) => module.ReplayList),
    ),
    isFullRight: true,
  },
  'sub-header': {
    Component: dynamic(() => import('../BlockList').then((module) => module.BlockList)),
  },
  'tag-list': {
    Component: dynamic(() => import('../../molecules/TagList').then((module) => module.TagList)),
  },
  'trending-tag-list': {
    Component: List('TagList'),
  },
  video: {
    Component: Standard('Video'),
  },
  'video-list': {
    Component: dynamic(() => import('../../molecules/VideoList').then((module) => module.VideoList)),
    noContainer: true,
  },
  header: {
    Component: dynamic(() => import('../BlockList').then((module) => module.BlockList)),
  },
  html: {
    Component: dynamic(() => import('../../atoms/HtmlBlock').then((module) => module.HtmlBlock)),
  },
  main: {
    Component: dynamic(() => import('../BlockList').then((module) => module.BlockList)),
  },
}

export type IBlock =
  | BlockTypes['action-button']
  | BlockTypes['all-news']
  | BlockTypes['article-author']
  | BlockTypes['article-bounce-folder']
  | { key: 'article-breadcrumb'; data: BlockTypes['breadcrumb']['data'] }
  | BlockTypes['breadcrumb']
  | BlockTypes['article-chapo']
  | BlockTypes['article-embed']
  | BlockTypes['article-extract']
  | BlockTypes['article-header-image']
  | BlockTypes['article-header-live']
  | BlockTypes['article-header-video']
  | { key: 'article-image'; data: IArticleImage }
  | { key: 'article-list-gen'; data: object }
  | BlockTypes['article-list']
  | BlockTypes['article-paragraph']
  | BlockTypes['article-quote']
  | BlockTypes['article-read-more']
  | BlockTypes['article-social-share']
  | BlockTypes['article-topic']
  | BlockTypes['article-video']
  | BlockTypes['author-header']
  | { key: 'author-list'; data: IAuthorList }
  | { key: 'bounce-notification'; data: IBounceNotification }
  | { key: 'promo'; data: IPromo }
  | BlockTypes['carousel-highlights']
  | { key: 'carousel-images'; data: ICarouselImages }
  | { key: 'carousel-newsletters'; data: ICarouselNewsletter }
  | { key: 'cookies-banner'; data: object }
  | BlockTypes['category-header']
  | BlockTypes['event-header']
  | { key: 'election-department-list'; data: object }
  | { key: 'election-district-list'; data: object }
  | BlockTypes['election-search']
  | BlockTypes['election-header']
  | { key: 'election-manual-results'; data: object }
  | { key: 'election-region-list'; data: object }
  | { key: 'election-results'; data: object }
  | { key: 'election-town-list'; data: object }
  | BlockTypes['event-category-header']
  | BlockTypes['event-vote']
  | { key: 'exam-header'; data: IHeaderTitleBlock }
  | { key: 'exam-search'; data: IExamenSearch }
  | { key: 'exam-search-results'; data: IExamSearchResults }
  | { key: 'exam-result'; data: IExamResult }
  | BlockTypes['gigya-screen']
  | { key: 'footer-nav'; data: object }
  | BlockTypes['header-page']
  | BlockTypes['header-navigation']
  | { key: 'header'; data: object }
  | BlockTypes['headlines']
  | BlockTypes['hello']
  | { key: 'home-user'; data: object }
  | BlockTypes['html']
  | BlockTypes['keyword-header']
  | BlockTypes['link-list']
  | BlockTypes['lives']
  | BlockTypes['live-list']
  | { key: 'main'; data: object }
  | BlockTypes['market-event-home']
  | { key: 'menu-asuivre'; data: object }
  | BlockTypes['menu-categories']
  | BlockTypes['menu-directs']
  | BlockTypes['menu-footer']
  | BlockTypes['menu-header']
  | { key: 'menu-links'; data: object }
  | { key: 'menu'; data: object }
  | BlockTypes['most-read-articles']
  | {
      key: 'newsfeed'
      data: INewsFeed
    }
  | BlockTypes['multi-live']
  | BlockTypes['outbrain']
  | { key: 'outbrain-drawer'; data: BlockTypes['outbrain']['data'] }
  | { key: 'pagination'; data: object }
  | { key: 'personality-details'; data: IPersonalityDetails }
  | { key: 'personality-header'; data: IPersonalityHeader }
  | { key: 'poool-access'; data: IPooolAccess }
  | { key: 'poool-engage'; data: IPooolEngage }
  | BlockTypes['program-header']
  | BlockTypes['program-list']
  | BlockTypes['program-video-list']
  | BlockTypes['pub']
  | { key: 'player-live'; data: { live: string; title: string; liveId: string } }
  | BlockTypes['rich-video']
  | BlockTypes['related-tags']
  | { key: 'search-results'; data: object }
  | { key: 'sub-header'; data: object }
  | BlockTypes['topic-chronology']
  | BlockTypes['topic-header']
  | BlockTypes['topic-list']
  | { key: 'tag-list'; data: ITagList }
  | { key: 'trending-tag-list'; data: ITagList }
  | BlockTypes['video']
  | BlockTypes['video-list']

export type BlockData<Key> = Extract<IBlock, { key: Key }>['data'] & { 'data-module'?: string }

export function Block({ name, data }: { name: string; data: object }): JSX.Element {
  const block = mappings[name]
  const blockData = Array.isArray(data) ? { name, data } : data

  if (!block || !data) {
    return null
  }

  if (block.noContainer) {
    return <block.Component data-module={name} {...blockData} />
  }

  return (
    <Container
      isFullWidthTabletLandscape={!!block.isFullWidthTabletLandscape}
      isFullWidthDesktop={!!block.isFullWidthDesktop}
      isFullRight={!!block.isFullRight}
      data-module={name}
    >
      <block.Component {...blockData} />
    </Container>
  )
}
