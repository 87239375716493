import type { IPageData } from '../../../types/page'
import { useAmp } from 'next/amp'
import config from '../../../config'
import { getDeviceName, getDeviceOS } from '../../../helpers/client'
import { DISPLAY_PAGE } from '../../../constants/tms'
import { getCookie } from "../../../helpers/cookies";
import {
  AAM_COOKIE_KEY,
  TC_UNIQUE_COOKIE_KEY,
  ATIDVISTOR_COOKIE_KEY
} from "../../../constants/cookies"

export interface IAMPTagCommanderProps {
  tms: IPageData['tms']
  url: string
}

export function AMPTagCommander({
  url,
  tms,
}: IAMPTagCommanderProps): JSX.Element {
  const isAmp = useAmp()
  const userAgent = !isAmp && window?.navigator?.userAgent
  if (!isAmp) {
    return null
  }

  const params = new URLSearchParams({
    ...(tms?.page || {}),
    id: DISPLAY_PAGE,
    screen_url: url,
    device_userAgent: userAgent,
    device_deviceName: getDeviceName(userAgent),
    device_deviceOs: getDeviceOS(userAgent),
    account_context_creation: '',
    user_userLogged: 'false',
    user_gigyaId: '',
    user_email_1: '',
    user_birthDate: '',
    user_gender: '',
    user_zipCode: '',
    user_dmpUid: getCookie(AAM_COOKIE_KEY) || '',
    user_tagCoUid: getCookie(TC_UNIQUE_COOKIE_KEY) || '',
    user_xitiUid: getCookie(ATIDVISTOR_COOKIE_KEY) || '',
    // AMP replaces these values
    user_consentString: 'CONSENT_STRING',
    user_consentState: 'CONSENT_STATE',
    timestamp: 'TIMESTAMP',
  })

  const tagCommanderConfig = {
    // TMS vars if needed
    vars: {},
    // Requests vars
    requests: {
      tC_BaseURL: config.tagcommander.ampBaseUrl,
      // Page variables mentioned in the "tC_PageTrack" element –>
      tC_PageTrack: `${config.tagcommander.ampBaseUrl}?${params}`,
    },
    // AMP triggers
    triggers: {
      // On pageview visible => call tC_PageTrack var
      pageview: {
        on: 'visible',
        request: 'tC_PageTrack',
      },
    },
  }

  return (
    <amp-analytics data-block-on-consent="_till_responded" type="atinternet" id="atinternet1">
      <script
        type="application/json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            tagCommanderConfig,
            null,
            config.environment === 'production' ? null : 4,
          ),
        }}
      />
    </amp-analytics>
  )
}
