import { useEffect } from 'react'
import { useRouter } from 'next/router'
import config from '../../../config'
import type { IBlockList } from '../../templates/BlockList/BlockList'
import { Block } from '../../templates/Block/Block'
import { mixins } from '../../../styles'
import { isServer } from '../../../helpers/client'

export function Home({ data }: IBlockList): JSX.Element {
  const { asPath } = useRouter()

  useEffect(() => {
    const homeRefreshInterval = Number(config.homeRefreshInterval) * 1000
    if (!isServer() && asPath === '/' && !isNaN(homeRefreshInterval)) {
      const timeoutId = setTimeout(() => {
        window?.location?.reload()
      }, homeRefreshInterval)
      return function clean() {
        clearTimeout(timeoutId)
      }
    }
  }, [asPath])

  if (asPath !== '/') {
    return null
  }

  return (
    <>
      {data.map(({ key, data: blockData }, index) => (
        <Block key={`${key}-${index}`} name={key} data={blockData} />
      ))}

      <style jsx global>{`
        #home-live-banner {
          display: flex;
          position: sticky;
          bottom: 0;
          z-index: 13;
        }

        @media ${mixins.mediaQuery.tablet} {
          #home-live-banner {
            display: none;
            visibility: hidden;
          }
        }
      `}</style>

      <style jsx>{`
        main {
          height: 100%;
          padding-bottom: 90px;
        }
      `}</style>
    </>
  )
}
